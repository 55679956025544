.navbar {
  @apply block py-5 container;
}

.navbarMenu {
  @apply hidden p-4;

  @screen lg {
    @apply hidden relative translate-x-0;

    width: unset;
    height: unset;
  }

  @apply flex fixed right-0 top-0 h-screen bg-white z-20;
  @apply transform translate-x-60 transition duration-300 ease-in-out;

  &.open {
    @apply transform translate-x-0 z-50 w-[375px] glx:w-[275px];
  }

  .wrapImg {
    @apply w-[40px] h-[40px] rounded-full bg-[#C4C4C4] flex justify-center items-center;
    .imageP {
      @apply w-full h-full rounded-full object-cover;
    }
  }
}

.overlay {
  @apply fixed bottom-0 left-0 z-40 w-full h-screen bg-[#00000042];
}
