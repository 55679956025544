.select {
  @apply flex flex-col items-center relative ;
  // box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);

  .options {
    @apply hidden absolute shadow top-full bg-white z-40 w-full left-0 rounded max-h-64 overflow-y-auto border-[#D0D0D0];
    @apply transition duration-300 ease-in-out;
    @apply mt-1 border shadow-sm;
  }

  &.open {
    .options {
      @apply block;
    }
  }

  .input {
    @apply text-gray-300 w-10 py-1 pl-2 pr-1 flex items-center justify-center border-[#D0D0D0];
  }
}

.selectIcon {
  @apply cursor-pointer w-6 h-6 text-gray-600 outline-none focus:outline-none;
  @apply transition duration-300 ease-in-out;
}

.optionItem {
  @apply cursor-pointer w-full border-gray-100;

  &:hover:not(.selected) {
    @apply bg-gray-100;
  }

  &.selected {
    @apply bg-white text-black;
  }
}

.textSelect {
  @apply first-line:flex  items-center  h-10 px-2 flex;
}

.disabled {
  @apply bg-[#F3F3F3];
}
